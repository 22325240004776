const options = {
  fullname: {
    required: true,
    minLength: 2
  },
  email: {
    required: true,
    isEmail: true
  },
  message: {
    required: true,
    minLength: 2
  },
  address: {
    isEmpty: true
  }
};

export default options;