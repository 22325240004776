import React from 'react';
import PropTypes from 'prop-types';
import SubheadWrap from './SubheadWrap';

const heading = (title = '') => {
  if (!title) return null;

  return (
    <SubheadWrap>
      <h3 className="h6 mb-1">{title}</h3>
    </SubheadWrap>
  );
};

function infoSection({ id, title, data, children }) {
  const hasData = Array.isArray(data) && data.length;
  const breakOn = 5;
  let content;

  if (!id) return null;

  if (hasData) {

    if (id === 'skills') {
      content = [];

      data.forEach((d, i) => {

        const column = i < breakOn ? 0 : 1;

        if (!(column in content)) {
          content.push([]);
        }

        content[column].push(
          <div key={i} className="row">
            <div className={'col-12' + (data.length !== (i + 1) ? ' mb-3' : '')}>
              <div className="d-inline-block">
                {heading(d.category)}
              </div>

              {
                d.items.length ? (
                  <ul className="unstyled-list pl-0 mb-0">
                    {d.items.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                ) : null
              }
            </div>
          </div>
        );
      });

      content = content.map((item, i) => {
        return (
          <div key={i} className="col-12 col-sm-6">
            {item}
          </div>
        );
      });
      
    } else {
      content = data.map((d, i) => {

        let range = '';
  
        if (d.start && d.end) {
          range = ` ${d.start} – ${d.end}`;
        } else if (d.start && !d.end) {
          range = ` ${d.start} – `;
        }
  
        return (
          <React.Fragment key={i}>
            <div className="col-xs-12 col-sm-6 col-lg-12">
              <div className="row h-100">
                <div className="col-12">
                  {heading(d.title)}
                </div>
  
                <div className="col-12">
                  <div className="row h-100 align-items-end">
                    <div className="col-12">
                      {d.role && <p className="mb-0">{d.role}</p>}
                      {d.program && <p className="mb-0">{d.program}</p>}
                    </div>
                    
                    <div className="col-12">
                       <p className="text-grey-dark mb-0">
                         <small>
                            {range && <>{range}</>}
                            {d.graduated && <>{d.graduated}</>}
                         </small>
                       </p>
                    </div>
                  </div>
                </div>
  
                {data.length !== (i + 1) && <div className="col-12"><div className="mb-3"></div></div>}
              </div>
            </div>
          </React.Fragment>
        );
      }); 
    }
  }

  const isAbout = id === 'about-me';

  return (
    <section className={(isAbout ? 'pr-lg-0 my-5 mt-lg-4 ' : '') + 'info-section position-relative'}>
      {id !== 'about-me' && 
        <h2 className="h4 d-sm-block mb-3 text-right text-sm-left">
          <a href={'#' + id} className="text-decoration-none">
            {title || id.split('-').map(word => word[0].toUpperCase() + word.substr(1)).join('-').replace('-', ' ')}
          </a>
          <div className="border-bottom border-thick border-grey-light mb-2 mt-1"></div>
        </h2>
      }
      {children}
      
      <div className="row">{content}</div>
    </section>
  ); 
}

infoSection.propTypes = {
  id: PropTypes.string.isRequired
};

export default infoSection;
