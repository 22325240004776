import validator from 'validator';

export const validateContactForm = (data, options = {}) => {
  const result = {};

  Object.keys(data).forEach(field => {
    const value = data[field];
    const errors = [];

    if (field in options) {
      const option = options[field];
      if (option.required && validator.isEmpty(value)) {
        errors.push('This field is required');
      }

      if (option.isEmpty && !validator.isEmpty(value)) {
        errors.push('Nice try bot!');
      }

      if (option.isEmail && !validator.isEmail(value)) {
        errors.push('Please enter a valid email');
      }

      if (option.minLength && !validator.isLength(value, { min: option.minLength })) {
        errors.push(`Please enter a value is that is at least ${option.minLength} characters in length`);
      }
    }

    if (errors.length) {
      result[field] = errors;
    }
  });

  return result;
};
