import React from 'react';

const data = {
  content: (
    <React.Fragment>
      <p>I am a mostly self-taught web developer and occasional graphic designer based out of London, Ontario, Canada.</p>
      <p>Solving problems with clean, reusable code is something that I am very passionate about.</p>
      <p>In my downtime I enjoy playing guitar, bouldering, playing video games or going for nature hikes.</p>
      <p className="mb-0">Currently, I am working at <a href="https://www.d2l.com/">D2L</a> out of their Kitchener office.</p>
    </React.Fragment>
  )
};

export default data;
