import React from 'react';

function subheadwrap({ children }) {
  return (
    <span className="d-inline-block border-bottom border-dotted border-top-0 border-right-0 border-left-0 border-grey-dark mb-1">
      {children}
    </span>
  );
}

export default subheadwrap;