import React from 'react';

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
      touchedTimeout: null,
      touchedDelay: 2000
    };

    this.changeInputHandler = this.changeInputHandler.bind(this);
    this.blurInputHandler = this.blurInputHandler.bind(this);
  }

  blurInputHandler(event) {
    this.props.update.call(this, event);

    this.setState(state => {
      clearTimeout(state.touchedTimeout);

      return {
        touched: true,
        touchedTimeout: null
      };
    });
  }

  changeInputHandler(event) {
    this.props.update.call(this, event);

    this.setState(state => {
      const newState = {...state};

      if (!state.touchedTimeout) {
        clearTimeout(state.touchedTimeout);
      }

      if (!state.touched) {
        newState.touchedTimeout = setTimeout(() => {
          this.setState({ touched: true });
        }, state.touchedDelay);
      }

      return newState;
    });
  }

  render() {
    const { id, value, error, type, formDirty, className, forwardedRef: ref } = this.props;
    const isDirty = error && (this.state.touched || formDirty);
    const classes = 'form-control bg-transparent border ' + (isDirty ? 'border-danger' : 'border-grey-dark');
    
    let field = <input ref={ref} type={type} className={classes} id={id} value={value} onBlur={this.blurInputHandler} onChange={this.changeInputHandler} />;

    if (type === 'textarea') {
      field = <textarea ref={ref} className={classes} id={id} value={value} onBlur={this.blurInputHandler} onChange={this.changeInputHandler} ></textarea>;
    }

    return (
      <div className={'form-group' + (className ? ` ${className}` : '')}>
        <label htmlFor={id}>{this.props.nicename}</label>
        
        {field}

        {isDirty && 
          <span className="error-message text-danger">{error}</span>
        }
      </div>
    );
  }
}

Input.defaultProps = {
  type: 'text'
};

export default Input;