import React from 'react';
import axios from 'axios';
import Input from '../components/Input';
import { validateContactForm } from '../utils';
import contactFormValidationOptions from '../configs/contactFormValidationOptions';

const fieldData = {
  fullname: {
    nicename: 'Full Name'
  },
  email: {
    nicename: 'Email',
    type: 'email'
  },
  message: {
    nicename: 'Message',
    type: 'textarea'
  }
};

const resetForm = () => ({
  fullname: '',
  email: '',
  message: '',
  sendCopy: false,
  address: '' // honeypot
});

const resetErrors = () => ({
  fullname: [],
  email: [],
  message: [],
  address: []
});

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: resetForm(),
      errors: resetErrors(),
      feedback: '',
      error: '',
      hideOverlay: true,
      standby: false,
      animationSpeed: 0.5,
      dirty: false
    };

    this.closeClickHandler = this.closeClickHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.changeInputHandler = this.changeInputHandler.bind(this);
    this.validate = this.validate.bind(this);
    this.isValid = this.isValid.bind(this);
    
    this.inputRefs = (() => {
      const refs = {};

      Object.keys(fieldData).forEach(fieldName => {
        refs[fieldName] = React.createRef();
      });

      return refs;
    })();
  }

  closeClickHandler(callback) {
    const { animationSpeed, error } = this.state;
    const newState = { feedback: '', hideOverlay: true };

    if (this.isValid() && !error) {
      newState.fields = resetForm();
    }

    setTimeout(() => {
      this.setState(newState, callback);
    }, 1000 * animationSpeed);
  }

  validate(callback = () => {}) {
    // validate form data
    const validationResult = validateContactForm(this.state.fields, contactFormValidationOptions);

    this.setState(() => ({
      errors: {
        ...resetErrors(),
        ...validationResult
      }
    }), callback);
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.props.toggled && prevState.hideOverlay) {
      this.inputRefs['fullname'].current.focus();
      this.setState({ hideOverlay: false });
    }
  }

  isValid() {
    return Object.keys(this.state.errors).every(key => this.state.errors[key].length === 0);
  }

  submitHandler(event) {
    event.preventDefault();

    const { animationSpeed } = this.state;

    new Promise(resolve => {
      this.setState(() => ({
        standby: true,
        feedback: '',
        dirty: true,
        errors: resetErrors()
      }), () => { setTimeout(resolve, 1000 * animationSpeed); });
    })
      .then(() => new Promise((resolve, reject) => {
        let isValid = false;
        
        this.validate(() => {
          isValid = this.isValid();

          // insult the bot
          isValid ? resolve() : reject({
            response: { data: { feedback: '', error: this.state.errors.address.length ? this.state.errors.address[0] : '' } }
          });
        });

        const fieldsWithErrors = Object.keys(this.state.errors).filter(key => this.state.errors[key].length);
        
        if (fieldsWithErrors.length && !isValid) {
          this.inputRefs[fieldsWithErrors[0]].current.focus();
        }

      }))
      .then(() => axios.post('/mailer.php', this.state.fields))
      .then(({ data }) => new Promise(resolve => {
        this.setState(() => ({
          feedback: data.feedback,
          standby: false
        }), resolve);
      }))
      .catch(err => {
        const { response } = err;
        const { status } = response;
        let error = err.response.data.error || 'Please fix the errors before attempting to resubmit.';
        let feedback = err.response.data.feedback || '';

        if (status >= 400) {
          error = 'There was an error.';
          feedback = `An error occurred when trying to submit the form. If you would still like to contact me, <a style="white-space: nowrap;" href="mailto:hireme@kyletozer.com">send me an email</a>.`;
        }

        this.setState(() => ({
          error,
          feedback,
          standby: false
        }));
      });
  }

  changeInputHandler(event) {
    const { value, id, type, checked } = event.target;

    this.setState(state => {
      const newState = {};
      let newValue = value; // defaults to update input string value field
      
      newState.fields = {...state.fields};
      
      if (type === 'checkbox') {
        newValue = checked;
      }

      newState.fields[id] = newValue;

      return newState;
    }, () => this.validate());
  }

  InputWrap = React.forwardRef((props, ref) => {
    return <Input {...props} forwardedRef={ref} />
  });

  render() {
    const { toggled } = this.props;
    const { feedback, standby, error, dirty } = this.state;
    const isValid = this.isValid();
    const success = feedback && isValid;

    const fields = (
      <React.Fragment>
        {
          Object.keys(fieldData).map((key, i) => {
            const { nicename, type } = fieldData[key];
            const error = this.state.errors[key].length ? this.state.errors[key][0] : '';
            return (
              <this.InputWrap ref={this.inputRefs[key]} key={i} id={key} type={type} nicename={nicename} error={error} value={this.state.fields[key]} update={this.changeInputHandler} formDirty={dirty} />

            );
          })
        }
      </React.Fragment>
    );

    return (
      <div id="contact-modal">
        <div className={ 'overlay position-fixed w-100 h-100 sticky-top animate__animated' + ( toggled ? ' animate__fadeIn' : ' animate__fadeOut' ) + ( this.state.hideOverlay ? ' d-none' : '' ) }></div>

        <div className={ 'modal d-block animate__animated position-fixed' + ( toggled ? ' animate__slideInDown' : ' animate__slideOutUp' ) } aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="contact-modal modal-content overflow-auto bg-yellow">
              <div className="modal-header border-bottom-0 z-top">
                <button type="button" className="close bg-darken transition-all" data-dismiss="modal" aria-label="Close contact form" onClick={event => {
                  this.props.toggleOff.call(this, event);
                  this.closeClickHandler();
                }}>
                  <span aria-hidden="true">
                    <i className="fa fa-xs fa-times"></i>
                  </span>
                </button>
              </div>

              <div id="feedback-success" className={ 'position-absolute w-100 h-100' + (feedback ? '' : ' d-none') }>
                <div className="w-100 h-100 d-flex justify-content-center align-items-center p-5 text-center">
                  <div dangerouslySetInnerHTML={{ __html: feedback }}></div>
                </div>
              </div>

              <form id="contact-form" className={ (success ? 'invisible' : '') } onSubmit={this.submitHandler} disabled={success} noValidate>
                <div className="modal-body">

                  <Input className="position-absolute" id="address" value={this.state.fields.address} update={this.changeInputHandler} />

                  {fields}

                  <div className="form-group text-center">
                    <div className="checkmark-wrap mb-0 d-inline-block">
                      <div className="d-inline-block">
                        <label htmlFor="sendCopy" className="position-relative pl-2 clickable">
                          <input className="form-check-input" id="sendCopy" type="checkbox" checked={this.state.fields.sendCopy} onChange={this.changeInputHandler} />
                          <span className="checkmark">
                            <i className="fa fa-check w-100"></i>
                          </span>
                          Send copy
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer justify-content-between border-top-0">

                  {/* <!-- Error Messages --> */}
                  <div>
                    <div id="feedback-errors" className={ 'text-danger' + (isValid ? ' d-none' : '') }>{error}</div>
                  </div>
                  
                  <div className="d-flex align-items-center">
                    <div id="form-standby" className={ 'spinner-border text-grey-dark mr-3' + (standby ? '' : ' d-none') } role="status">
                      <span className="sr-only">Loading...</span>
                    </div>

                    <button type="submit" className="btn btn-secondary" disabled={standby}>Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;
