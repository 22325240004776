const data = [
  {
    href: 'tel:15198600494',
    iconClasses: ['fas', 'fa-mobile'],
    ariaLabel: 'Call me'
  },
  {
    id: 'contact',
    anchorClasses: ['clickable'],
    iconClasses: ['fas', 'fa-envelope'],
    clickHandler: true,
    ariaLabel: 'Open the contact form'
  },
  {
    href: 'https://github.com/kyletozer',
    iconClasses: ['fab', 'fa-github'],
    ariaLabel: 'GitHub'
  },
  {
    href: 'https://www.linkedin.com/in/kyle-tozer-83034b93/',
    iconClasses: ['fab', 'fa-linkedin'],
    ariaLabel: 'Linkedin'
  },
  {
    href: 'https://codepen.io/kyletozer',
    iconClasses: ['fab', 'fa-codepen'],
    ariaLabel: 'Codepen'
  }
];

export default data;