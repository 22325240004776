import React from 'react';

class Image extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultSrc: null,
            extraClasses: [
                'progressive-load',
                'opacity-0'
            ]
        };
    }

    componentDidUpdate(prevProps) {
        const { src } = this.props;

        if (prevProps.src === src) {
            return;
        }

        this.setState(state => {
            const extraClasses = state.extraClasses.slice();
            const classIndex = state.extraClasses.indexOf('opacity-0');

            extraClasses.splice(classIndex, 1);

            return {
                ...state,
                ...{ extraClasses, defaultSrc: src }
            };
        });
    }

    render() {
        const { alt, className } = this.props;
        const { defaultSrc, extraClasses } = this.state;
        const classes = `${extraClasses.join(' ')} ${className}`;

        return (
            <>
                <img className={classes} src={defaultSrc} alt={alt} />
            </>
        );
    }
}

export default Image;