import React from 'react';
import socialData from '../data/social-data';
import global from '../data/global';

const { breakpoint } = global;

const adjustIcons = (bp = breakpoint) => {
  let iconSize = 'lg';
  let iconSpacing = '2';

  if (window.innerWidth > breakpoint) {
    iconSize = 'md';
    iconSpacing = '0';
  }

  return { iconSize, iconSpacing };
};

class Social extends React.Component {
  
  state = {...adjustIcons()};
  
  updateIcons = () => {
    this.setState({...adjustIcons()});
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateIcons);
  }

  render() {
    const { toggleOff, className, iconStates, style } = this.props;
    const { iconSize, iconSpacing } = this.state;

    const socialItems = socialData
      .map(({ id, href, anchorClasses, iconClasses, clickHandler, ariaLabel }, i) => {
        return (
          <li key={i} className={'social-link-wrap list-inline-item text-md-center mr-0 ml-' + (i === 0 ? '0' : iconSpacing)}>
            <div className="d-flex align-items-center justify-content-center w-100 h-100">
              <a id={id} className={'social-link mt-lg-0 p-0 w-100 h-100 d-flex justify-content-center align-items-end pb-2 text-decoration-none' + (anchorClasses ? ' ' + anchorClasses.join(' ') : '') + (id && id in iconStates && iconStates[id] ? ' social-link-active' : '')} href={href ? href : '#'} onClick={clickHandler ? e => toggleOff.call(this, e) : () => {}} aria-label={ariaLabel}>
                {iconClasses && (
                  <span className={'position-relative text-decoration-none fa-' + iconSize + ' ' + iconClasses.join(' ')}></span>
                )}
              </a>
            </div>
          </li>
        );
      });
    
    return (
      <nav className={'text-center text-md-right' + (className ? ` ${className}` : '')} style={style}>
        <ul className="social-wrapper list-inline d-inline-block mb-0">{socialItems}</ul>
      </nav>
    );
  }
}

export default Social;
